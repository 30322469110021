/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

function SEO({ description, lang, meta, title, slug, image }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            siteUrl
            authorName
            image
            siteLanguage
          }
        }
      }
    `
  );

  const { title: defaultTitle } = site.siteMetadata;
  const metaTitle = title
    ? `${title} | ${site.siteMetadata.title}`
    : defaultTitle;
  const metaDescription = description || site.siteMetadata.description;
  const metaUrl = `${site.siteMetadata.siteUrl}${slug}`;
  const metaImage = `${site.siteMetadata.siteUrl}${
    image || site.siteMetadata.image
  }`;
  const metaLanguage = lang || site.siteMetadata.language;

  const schemaOrgJSONLD = [
    {
      '@context': 'http://schema.org',
      '@type': 'WebSite',
      url: metaUrl,
      name: title,
      alternateName: site.siteMetadata.titleShort,
    },
  ];

  return (
    <Helmet
      htmlAttributes={{
        lang: metaLanguage,
      }}
      title={title}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `image`,
          content: metaImage,
        },
        {
          property: `og:title`,
          content: metaTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:url`,
          content: metaUrl,
        },
        {
          property: `og:locale`,
          content: metaLanguage,
        },
        {
          property: `og:image`,
          content: metaImage,
        },
        {
          property: `og:site_name`,
          content: site.siteMetadata.title,
        },
        {
          property: `og:image:alt`,
          content: 'Banner',
        },
        {
          property: `og:image:type`,
          content: 'image/png',
        },
        {
          property: `og:image:width`,
          content: 1200,
        },
        {
          property: `og:image:height`,
          content: 630,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          property: `twitter:title`,
          content: metaTitle,
        },
        {
          property: `twitter:image`,
          content: metaImage,
        },
        {
          property: `twitter:image:src`,
          content: metaImage,
        },
        {
          property: `twitter:image:alt`,
          content: 'Banner',
        },
        {
          property: `twitter:image:width`,
          content: 1200,
        },
        {
          property: `twitter:image:height`,
          content: 630,
        },
        {
          name: `twitter:site`,
          content: site.siteMetadata?.author || ``,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata?.author || ``,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    >
      <script type="application/ld+json">
        {JSON.stringify(schemaOrgJSONLD)}
      </script>
    </Helmet>
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
  slug: '',
  image: '',
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  slug: PropTypes.string,
  image: PropTypes.string,
};

export default SEO;
